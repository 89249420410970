// @ts-expect-error - TS7016 - Could not find a declaration file for module 'legacy/content_security_policy_helper'.
import { findAndConvertMetadata } from 'legacy/content_security_policy_helper';

const observer = new MutationObserver((mutations) => {
  mutations.forEach((mutation) => {
    // if any of the added nodes are script tags, we need to check if they are
    // the CSP script tag and if so, convert the metadata
    const metaDataTagsChanged = Array.from(mutation.addedNodes).some(
      (node) =>
        (node as HTMLElement).tagName === 'SCRIPT' &&
        (node as HTMLElement).classList.contains('js-metadata')
    );

    if (metaDataTagsChanged) {
      findAndConvertMetadata();
    }
  });
});

observer.observe(document.documentElement, {
  childList: true,
  subtree: true,
});

findAndConvertMetadata();
